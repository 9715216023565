import React, { useState } from "react";
import { purchaseTokensFree } from "../eth-setup/apis";
import "../Style/Mint.css";

function Mint() {
  const [qty, setQty] = useState(1);
  const maxVal = 1;

  return (
    <div className="col-lg-6">
      <div id="mint">
        <div className="mint__container">
          <img src="/images/GT-logo.gif" className="title__gif" />
          <div className="inputs">
            {/* <div>
              <input
                onChange={(e) => {
                  if (Number(e.target.value) > maxVal) setQty(maxVal);
                  else setQty(e.target.value);
                }}
                onClick={(e) => {
                  if (Number(e.target.value) > maxVal) setQty(maxVal);
                  else setQty(e.target.value);
                }}
                min={0}
                max={maxVal}
                type="number"
                className="PhoneButton"
                placeholder="0"
                value={qty}
              />
            </div> */}
            <div>
              {/* <a
                className="PhoneButton"
                onClick={() => purchaseTokensFree(() => {}, qty)}
              >
                Mint Your Gandalf Here
              </a> */}
            </div>
            <div>
              <a
                href="https://opensea.io/collection/gandalftownwtf"
                className="PhoneButton"
              >
                SOLD OUT
              </a>
              <a
                href="https://opensea.io/collection/gandalftownwtf"
                className="PhoneButton"
              >
                View On Opensea
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mint;
