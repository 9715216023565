import React from "react";
import "../Style/Mint.css";

function Note() {
  return (
    <div className="col-lg-6">
      <div id="Actions">
        <div className="note text-center">
          <header>
            <p>
              <strong>1 free + gas mint per wallet.</strong> Don't be greedy.
              That's how Gandalf created the legend. 9999 max supply.
            </p>
          </header>
          <img className="divide" src="/images/GT-phone-header-border.png" />
          <main>
            <div className="row flex-column align-items-center">
              <div className="col-lg-6 col-md-5 col-sm-4 d-flex justify-content-center">
                <img id="Cage" src="/images/GT-cage.gif" className="nft__img" />
              </div>
              <div className="col-12 d-flex align-items-center justify-content-center">
                <p className="mt-4">
                  <strong>We are reserving 1,000 Gandalfs.</strong> Because
                  that's what the Wise Man want.
                </p>
              </div>
            </div>
          </main>
          <img className="divide" src="/images/GT-phone-header-border.png" />
          <footer>
            <p>
              No roadmap. No Discord. No utility. CC0.{" "}
              <a
                href="https://etherscan.io/address/0x18613D38367ddE6522D36f3546b9777880d88cA3#code"
                target="_blank"
              >
                Contract
              </a>{" "}
              was actually written by Gandalf The White.
            </p>
          </footer>
          <div id="Follow">
            <span>#GANDALFOLLOWGANDALF</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Note;
